<template lang="html">
  <div class="your-contribution">
    <div class="your-contribution__label">Ihr Beitrag:</div>
    <div class="your-contribution__value">
      {{ contributionValue.toFixed(2).toString().replace('.', ',') }} €
    </div>
  </div>
</template>

<script>
export default {
  name: 'YourContribution',
  props: {
    contributionValue: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
